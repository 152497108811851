<template>
  <v-container fluid fill-height>
    <v-row class="fill-height" v-if="timeEditLink == ''">
      <v-col v-for="(klass, i) in classes" :key="i" :cols="getWidthByNum(i)">
        <v-btn class="schedule-btn px-0 primary--text" block outlined :to="'/schema/' + klass[0]">
          <span class="text-h5">{{klass[1]}}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-container fill-height class="pb-10" v-if="timeEditLink != ''">
      <router-link to="/schema"><a><h3><textloader-component textKey="ÅtervändsLänkScheman"/></h3></a></router-link>
      <iframe :src="this.timeEditLink" width="100%" height="100%" v-bind:style="{filter: 'invert(' + darkMode + '%)'}"></iframe>
    </v-container>
  </v-container>
</template>

<style lang="sass">
.schedule-btn
  height: 100% !important
  border-width: 2px
</style>

<script>
import TextloaderComponent from '@/components/TextloaderComponent'

export default {

  name: 'Schedule',
  data: () => {
    return {
      classes: [
        ['f1a', 'F1 A'],
        ['f1b', 'F1 B'],
        ['f1c', 'F1 C'],
        ['f2a', 'F2 A'],
        ['f2b', 'F2 B'],
        ['f2c', 'F2 C'],
        ['f3a', 'F3 A'],
        ['f3b', 'F3 B'],
        ['f3c', 'F3 C'],
        ['f4t', 'F4 T'],
        ['f4b', 'F4 B'],
        ['f4i', 'F4 I'],
        ['f5t', 'F5 T'],
        ['f5b', 'F5 B'],
        ['f5i', 'F5 I']
      ],
      numInYear: [3, 3, 3, 3, 3]
    }
  },
  props: {
    classID: String
  },
  components: {
    TextloaderComponent
  },
  computed: {
    isDark: function () { return this.$vuetify.theme.dark },
    darkMode () { return this.isDark * 100 },
    timeEditLink: function () {
      if (this.classID === undefined) return ''
      return this.getLink(this.classID.toLowerCase(), this.$store.getters.lang === 'eng', this.isDark)
    }
  },
  methods: {
    getWidthByNum: function (i) {
      for (const n of this.numInYear) {
        i -= n
        if (i < 0) { return Math.floor(12 / n) }
      }
      return 1
    },
    getLink: function (klass, isEng, isDark) {
      const getClassCode = function (k) {
        switch (klass) {
          case 'f1a': return 251399
          case 'f1b': return 251400
          case 'f1c': return 251401
          case 'f1d': return 251402

          case 'f2a': return 251403
          case 'f2b': return 251404
          case 'f2c': return 251405
          case 'f2d': return 251406

          case 'f3a': return 251407
          case 'f3b': return 251408
          case 'f3c': return 251409

          case 'f4t': return 251418
          case 'f4b': return 251414
          case 'f4i': return 456594

          case 'f5t': return 251422
          case 'f5b': return 251419
          case 'f5i': return 999423

          // case 'f5t': return 'https://cloud.timeedit.net/uu/web/schema/s.html?sid=1002&object=F5.T&cls=188&type=programme&p=0.m%2C12.w'
          // case 'f5b': return 'https://cloud.timeedit.net/uu/web/schema/s.html?sid=1002&object=F5.B&cls=188&type=programme&p=0.m%2C12.w'
          // case 'f5i': return 'https://cloud.timeedit.net/uu/web/schema/s.html?sid=1002&object=F5.Is&cls=188&type=programme&p=0.m%2C12.w'
        }
      }
      const s = `https://cloud.timeedit.net/uu/web/schema/ri.html?h=f&sid=1002&p=0.w%2C20220116.x&objects=${getClassCode(klass)}.207&ox=0&types=0&fe=0&g=t&cl=${(isDark ? 190 : 188)}${(isEng) ? '&l=en' : ''}&ww=1&hsid=t&h2=t`
      console.log(s)
      return s
    }
  },
  created: function () {
    if (this.classID === undefined) {
      try {
        const senasteSchema = this.$store.getters.latestSchedule
        if (senasteSchema === undefined) return
        this.$router.push('/schema/' + senasteSchema)
      } catch (e) {}
    }
  },
  watch: {
    timeEditLink (newValue) {
      if (this.classID !== undefined && newValue !== '') {
        this.$store.commit('changeLatestSchedule', this.classID.toLowerCase())
      }
    }
  }
}

</script>
